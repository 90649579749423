import { memo } from 'react';

import { HeaderBoardAndFieldsInfo } from '~/components/AssetModal/Header/components/HeaderBoardAndFieldsInfo';
import { HeaderCloseButton } from '~/components/AssetModal/Header/components/HeaderCloseButton';
import { PublicBoardAssetHeaderShareButton } from '~/components/AssetModal/Header/components/PublicBoardAssetHeaderShareButton';
import { Header } from '~/components/AssetModal/Header/Header';
import { useCloseAssetModal } from '~/components/AssetModal/shared/useCloseAssetModal';
import { PublicAssetModalHeaderTitle } from '~/components/PublicAssetModal/components/PublicAssetModalHeaderTitle';
import { PublicDownloadButton } from '~/components/PublicAssetModal/components/PublicDownloadButton';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { usePublicWorkspace } from '~/components/PublicWorkspaceProvider';
import { usePublicAssetCustomFieldsData } from '~/hooks/usePublicAssetCustomFieldsData';
import {
  canChangeAssetCustomFields,
  canDownloadVersion,
  canSeeAssetCustomFields,
  canViewAssetDiscussions,
} from '~/utils/permissions/assetPermissions';

export const PublicBoardAssetModalHeader = memo(() => {
  const { closeAssetModal } = useCloseAssetModal();

  const { permissions } = usePublicPermissions();
  const canEditCustomFields = canChangeAssetCustomFields(permissions);
  const canViewCustomFields = canSeeAssetCustomFields(permissions);
  const canViewDiscussions = canViewAssetDiscussions(permissions);

  const { data: customFields } = usePublicAssetCustomFieldsData();
  const canDownload = canDownloadVersion(permissions);
  const { workspaceName } = usePublicWorkspace();

  return (
    <Header
      headerLeft={
        <>
          <PublicAssetModalHeaderTitle />
          <HeaderBoardAndFieldsInfo
            fields={customFields?.data}
            onClose={closeAssetModal}
            canViewCustomFields={canViewCustomFields}
            canEditCustomFields={canEditCustomFields}
            showComments={canViewDiscussions}
            workspaceName={workspaceName}
          />
        </>
      }
      headerRight={
        <>
          <PublicBoardAssetHeaderShareButton />
          {canDownload && (
            <div className="ml-3">
              <PublicDownloadButton />
            </div>
          )}
          <HeaderCloseButton onClose={closeAssetModal} />
        </>
      }
    />
  );
});

PublicBoardAssetModalHeader.displayName = 'PublicBoardAssetModalHeader';
