import { EllipsisVertical } from '@air/next-icons';
import { DropdownMenu, DropdownMenuOption, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { useAirModal } from '@air/provider-modal';
import { useShortIdContext } from '@air/provider-short-id';
import { IconButton } from '@air/zephyr';
import { noop } from 'lodash';
import { memo, useCallback, useMemo } from 'react';

import { SharePublicClipModal } from '~/components/Modals/SharePublicClipModal';
import { centralizedClipIdSelector } from '~/store/centralizedClip/selectors';
import { getPublicClipShareUrl } from '~/swr-hooks/shortUrl/utils';
import { useAirStore } from '~/utils/ReduxUtils';

import { usePublicDownloadOptions } from '../shared/hooks/usePublicDownloadOptions';

export const PublicMobileEllipsisMenu = memo(() => {
  const downloadOptions = usePublicDownloadOptions();
  const [sharePublicClip] = useAirModal(SharePublicClipModal);
  const store = useAirStore();
  const { shortId } = useShortIdContext();

  const onShareClick = useCallback(() => {
    const clipId = centralizedClipIdSelector(store.getState());

    if (!clipId) return;
    sharePublicClip({
      id: clipId,
      url: getPublicClipShareUrl(shortId),
      trackLocation: 'public-asset-page',
    });
  }, [sharePublicClip, shortId, store]);

  const options = useMemo<DropdownMenuOption[]>(() => {
    return [
      {
        id: 'share',
        label: 'Share',
        onSelect: onShareClick,
        type: 'item',
      },
      ...downloadOptions,
    ];
  }, [downloadOptions, onShareClick]);

  return (
    <DropdownMenu
      trigger={
        <IconButton
          variant="button-unstyled"
          onClick={noop}
          icon={EllipsisVertical}
          size="medium"
          tx={{
            mr: 12,
            color: 'pigeon100',
            ['&:hover']: {
              backgroundColor: 'pigeon700',
              color: 'pigeon050',
            },
            ['&.isDisabled']: {
              color: 'pigeon400',
            },
          }}
        >
          See options
        </IconButton>
      }
    >
      {renderDropdownItems({ options })}
    </DropdownMenu>
  );
});

PublicMobileEllipsisMenu.displayName = 'PublicMobileEllipsisMenu';
