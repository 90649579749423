import { Box } from '@air/zephyr';
import { memo, ReactNode } from 'react';
export interface AssetModalMobileTabNavigationProps {
  tabs: ReactNode;
}

export const AssetModalMobileTabNavigation = memo(({ tabs }: AssetModalMobileTabNavigationProps) => {
  return (
    <Box
      tx={{
        width: '100%',
        height: 56,
        bg: 'pigeon850',
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        ['&.isEmpty']: {
          justifyContent: 'flex-end',
        },
      }}
    >
      {tabs}
    </Box>
  );
});

AssetModalMobileTabNavigation.displayName = 'AssetModalMobileTabNavigation';
