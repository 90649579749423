import { Stack } from '@air/next-icons';
import { memo } from 'react';

import { DarkIconButton } from '~/components/AssetModal/shared/components/DarkIconButton';
import { AssetModalPanel, useAssetModalPanelContext } from '~/providers/AssetModalPanelContextProvider';

export const MobileVersionsTab = memo(() => {
  const { showPanel } = useAssetModalPanelContext();

  return (
    <DarkIconButton
      testId="VERSIONS_PANEL"
      icon={Stack}
      onClick={() => showPanel(AssetModalPanel.VERSIONS)}
      size="medium"
    >
      Versions panel
    </DarkIconButton>
  );
});

MobileVersionsTab.displayName = 'MobileVersionsTab';
