import { Board, PublicBoard } from '@air/api/types';
import { Tooltip } from '@air/primitive-tooltip';
import { memo, ReactNode, useMemo } from 'react';

import { CustomFieldListWithOverflow } from '~/components/CustomFields/CustomFieldListWithOverflow';
import { BoardGalleryCardMenu } from '~/components/Gallery/BoardGalleryCard/BoardGalleryCardMenu';
import { BoardGalleryCardThumbnail } from '~/components/Gallery/BoardGalleryCard/BoardGalleryCardThumbnail';
import { GalleryCard, GalleryCardProps } from '~/components/Gallery/GalleryCard/GalleryCard';
import { GalleryCardContent } from '~/components/Gallery/GalleryCard/GalleryCardContent';
import { GalleryCardFooter } from '~/components/Gallery/GalleryCard/GalleryCardFooter';
import { GalleryCardImageWrapper } from '~/components/Gallery/GalleryCard/GalleryCardImageWrapper';
import { SelectedGalleryCardOverlay } from '~/components/Gallery/GalleryCard/SelectedGalleryCardOverlay';
import { GalleryCardCustomFieldsAction } from '~/components/Gallery/GalleryCardCustomFieldsAction';
import { GalleryItemType } from '~/components/Gallery/types';
import { ImgixImageParams } from '~/components/ImgixImage';
import { LibraryCustomField } from '~/components/LibraryBeta/hooks/queries/useLibraryCustomFields';
import { BOARD_CARD, BOARD_CARD_TITLE, BOARD_META } from '~/constants/testIDs';
import { visibleCustomFieldsViewOptionsSelector } from '~/store/configViews/selectors';
import { SelectableGalleryBoardItem } from '~/store/selectedItems/types';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface BoardGalleryCardProps<B extends Board | PublicBoard>
  extends Pick<
    GalleryCardProps<SelectableGalleryBoardItem<B>>,
    'getButtonClassName' | 'getItemMenuOptions' | 'getSelectionMenuOptions' | 'isSelectable'
  > {
  board: B;
  libraryCustomFields?: LibraryCustomField[];
  cardWidth: number;
  onClick: () => void;
  onCmdClickTitle?: () => void;
  ancestors?: ReactNode;
  sizes?: ImgixImageParams['sizes'];
  canViewCustomFields: boolean;
}

function _BoardGalleryCard<B extends Board | PublicBoard>({
  board,
  cardWidth,
  getItemMenuOptions,
  getSelectionMenuOptions,
  isSelectable = true,
  libraryCustomFields,
  onClick,
  onCmdClickTitle,
  ancestors,
  sizes,
  canViewCustomFields,
  getButtonClassName,
}: BoardGalleryCardProps<B>) {
  const visibleCustomFields = useAirSelector(visibleCustomFieldsViewOptionsSelector);

  const popoverCustomFields = useMemo(() => {
    if (!!libraryCustomFields?.length) {
      return board?.customFields?.filter((board) => {
        return !!libraryCustomFields.find((customField) => customField.id === board.id)?.visible;
      });
    }

    return board.customFields;
  }, [board.customFields, libraryCustomFields]);

  const boardCustomFields = useMemo(() => {
    if (!!libraryCustomFields?.length) {
      return board.customFields?.filter((boardField) => {
        const libraryField = libraryCustomFields.find((customField) => customField.id === boardField.id);
        const visibleField = visibleCustomFields.find((vcf) => vcf.customFieldId === boardField.id);

        return libraryField?.visible && visibleField;
      });
    }

    return board.customFields?.filter((customField) =>
      visibleCustomFields.find((vcf) => vcf.customFieldId === customField.id),
    );
  }, [board.customFields, libraryCustomFields, visibleCustomFields]);

  return (
    <GalleryCard
      testId={BOARD_CARD}
      title={board.title}
      getItemMenuOptions={getItemMenuOptions}
      getSelectionMenuOptions={getSelectionMenuOptions}
      item={{
        id: board.id,
        item: board,
        type: GalleryItemType.board,
      }}
      onClick={onClick}
      isSelectable={isSelectable}
      getButtonClassName={getButtonClassName}
    >
      {({ setIsMenuOpen, isHovering, isSelected }) => (
        <>
          <GalleryCardImageWrapper>
            {board.thumbnails && board.thumbnails.length > 0 && (
              <BoardGalleryCardThumbnail board={board} sizes={sizes} height="100%" />
            )}
          </GalleryCardImageWrapper>
          {isSelected && <SelectedGalleryCardOverlay />}
          <GalleryCardContent>
            <div className="flex">
              {canViewCustomFields && !!popoverCustomFields?.length && (
                <div className="pointer-events-auto ml-2 mt-2">
                  <GalleryCardCustomFieldsAction customFields={popoverCustomFields} />
                </div>
              )}
              {isHovering && (
                <div className="pointer-events-auto mr-2 mt-2 flex grow justify-end">
                  <BoardGalleryCardMenu
                    board={board}
                    getItemMenuOptions={getItemMenuOptions}
                    getSelectionMenuOptions={getSelectionMenuOptions}
                    setIsMenuOpen={setIsMenuOpen}
                  />
                </div>
              )}
            </div>
            <GalleryCardFooter>
              <Tooltip label={board.title} side={'top'} sideOffset={4} align="start">
                <button
                  onClick={(event) => {
                    if ((event.metaKey || event.ctrlKey) && !!onCmdClickTitle) {
                      onCmdClickTitle();
                    } else {
                      onClick();
                    }
                  }}
                  className="pointer-events-auto text-left text-20 font-semibold leading-none text-white hover:underline"
                  data-testid={BOARD_CARD_TITLE}
                >
                  <div
                    /**
                     * We add a pb-0.5 to the line-clamp-3 to make sure that the
                     * text doesn't get cut off
                     */
                    className="line-clamp-3 break-words pb-0.5"
                  >
                    {board.title}
                  </div>
                </button>
              </Tooltip>

              {!!boardCustomFields?.length && (
                <div className="pointer-events-auto">
                  <CustomFieldListWithOverflow
                    className="mt-1 py-1"
                    customFields={boardCustomFields}
                    allowedWidth={cardWidth}
                    hasDarkBackground
                    shouldShowTooltip
                  />
                </div>
              )}

              {!!ancestors && (
                <p className="mt-0.5 pr-7 text-12 text-white" data-testid={BOARD_META}>
                  <>in&nbsp;{ancestors}</>
                </p>
              )}
            </GalleryCardFooter>
          </GalleryCardContent>
        </>
      )}
    </GalleryCard>
  );
}

export const BoardGalleryCard = memo(_BoardGalleryCard) as typeof _BoardGalleryCard;
