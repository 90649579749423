import styled, { css } from 'styled-components';

/** This is used to add spacing behind the fixed header when using iOSSafariFixedElementHack  */
export const IOSSafariFixedElementHackBuffer = styled.div<{
  height: number;
}>`
  /* CSS specific to iOS devices */
  @supports (-webkit-touch-callout: none) {
    /* helps move content out from under header */
    margin-top: ${({ height }) => `calc(${height} * 1.7)`};
  }
`;
/**
  * Explanation of why the below iOS specific stuff exists:
  * NOTE: this was found in iOS13
  * Safari triggers resize events on scroll and touch events.
  * We use a resize listener on the container which would get marked as sticky.
  * This leads to the container's height constantly being recalculated.
  * This means there are staggered and brief moments of height being marked as 0.
  * Those moments may be left unrectified by a misqueueing of the calculations until another event fixes it.
  * This leads to the disappearance of the element on touch and the flickering of it on scroll.

  * 1) The the GPU acceleration prevents the flickering on scroll for fixed/sticky elements.
  * 2) The switch to fixed ensures we dont care about the recalculating container for touch events on this element or it's children.
*/
export const iOSSafariFixedElementHackTx = {
  /* 1) */
  /* CSS specific to iOS devices */
  '@supports (-webkit-touch-callout)': {
    position: 'fixed',
    width: '100vw',
  },

  /* 2) */
  /* Force iOS GPU Acceleration to prevent most flickering during scroll */
  '-webkit-transform': 'translate3d(0, 0, 0)',
};

/**
  * Explanation of why the below iOS specific stuff exists:
  * NOTE: this was found in iOS13
  * Safari triggers resize events on scroll and touch events.
  * We use a resize listener on the container which would get marked as sticky.
  * This leads to the container's height constantly being recalculated.
  * This means there are staggered and brief moments of height being marked as 0.
  * Those moments may be left unrectified by a misqueueing of the calculations until another event fixes it.
  * This leads to the disappearance of the element on touch and the flickering of it on scroll.

  * 1) The the GPU acceleration prevents the flickering on scroll for fixed/sticky elements.
  * 2) The switch to fixed ensures we dont care about the recalculating container for touch events on this element or it's children.
*/
export const iOSSafariFixedElementHackSx = css`
  /* 1) */
  /* CSS specific to iOS devices */
  @supports (-webkit-touch-callout: none) {
    position: fixed;
    width: 100vw;
  }

  /* 2) */
  /* Force iOS GPU Acceleration to prevent most flickering during scroll */
  -webkit-transform: translate3d(0, 0, 0);
`;
