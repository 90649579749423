import { VisuallyHidden } from '@air/primitive-visually-hidden';
import Link from 'next/link';
import { memo } from 'react';

import { Logo } from '~/components/Logo';
import { Routes } from '~/constants/routes';
import { NEW_TAB } from '~/constants/urls';

export const AirLogoLink = memo(() => (
  <Link
    className="mr-1 flex h-10 w-10 items-center justify-center"
    href={Routes.root}
    target={NEW_TAB}
    rel="noopener noreferrer"
  >
    <VisuallyHidden>Go to landing page</VisuallyHidden>
    <Logo aria-hidden="true" className="h-4 fill-blue-9" />
  </Link>
));

AirLogoLink.displayName = 'AirLogoLink';
