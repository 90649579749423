import { Lock } from '@air/next-icons';
import { Box } from '@air/zephyr';
import { memo } from 'react';

import { getKeyForShortIDVerificationStatus } from '~/utils/SessionHelpers';

import { PasswordPageBackground } from './PasswordPageBackground';
import { PasswordPageHeader } from './PasswordPageHeader/PasswordPageHeader';
import { PasswordPageInput } from './PasswordPageInput/PasswordPageInput';

export interface PasswordPageProps {
  shortId: string;
  onVerified: () => Promise<void>;
}

export const PasswordPage = memo(({ shortId, onVerified }: PasswordPageProps) => {
  const onVerifiedHandler = async () => {
    window.sessionStorage.setItem(getKeyForShortIDVerificationStatus(shortId), 'verified');
    await onVerified();
  };

  return (
    <Box
      tx={{
        height: '100vh',
        display: 'flex',
        position: 'relative',
        zIndex: 1,
        overflow: 'hidden',
      }}
    >
      <PasswordPageBackground />
      <PasswordPageHeader />
      <Box
        tx={{
          width: '100%',
          maxWidth: '350px',
          alignSelf: 'center',
          margin: 'auto',
          padding: '24px',
        }}
      >
        <Box
          as={Lock}
          tx={{
            color: 'pigeon500',
            '& path': { stroke: 'currentColor' },
            width: 32,
            ml: -5,
          }}
        />
        <div className="mt-6 text-24 font-semibold text-grey-12">This link is private</div>
        <div className="my-4 text-16 text-grey-11">Please enter the passcode to view</div>
        <PasswordPageInput shortId={shortId} onVerified={onVerifiedHandler} />
      </Box>
    </Box>
  );
});

PasswordPage.displayName = 'PasswordPage';
