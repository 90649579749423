import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import BoardThumbnail, { BoardThumbnailProps } from '~/components/BoardThumbnail/BoardThumbnail';
import { useThumbnailPreference } from '~/hooks/useThumbnailPreference';
import { CentralizedBoardSelectors } from '~/store/centralizedBoard/selectors';

export type BoardGalleryCardThumbnailProps = Pick<BoardThumbnailProps, 'board' | 'height' | 'sizes'>;

export const BoardGalleryCardThumbnail = ({ board, height, sizes }: BoardGalleryCardThumbnailProps) => {
  const { getPreference } = useThumbnailPreference();
  const boardId = useSelector(CentralizedBoardSelectors.boardId);

  const thumbnailPreference = useMemo(() => {
    if (boardId) {
      return getPreference(boardId);
    }

    return undefined;
  }, [boardId, getPreference]);

  return (
    <BoardThumbnail
      board={board}
      objectFit={thumbnailPreference === 'default' ? 'cover' : thumbnailPreference}
      imgixParams={{
        fit: thumbnailPreference === 'default' ? 'crop' : 'clip',
      }}
      height={height}
      sizes={sizes}
      tx={{ cursor: 'inherit' }}
      width="100%"
    />
  );
};
