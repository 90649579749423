import { useBreakpointsContext } from '@air/provider-media-query';
import { Box, BoxProps } from '@air/zephyr';
import { memo, MutableRefObject, ReactNode, useEffect } from 'react';

import { iOSSafariFixedElementHackTx } from '~/components/iOSFixedElementHack';
import { SEARCH_STICKY_HEADER_CONTAINER } from '~/constants/testIDs';
import {
  HEADER_DESKTOP_HORIZONTAL_PADDING,
  HEADER_MOBILE_HORIZONTAL_PADDING,
  SEARCH_STATIC_HEADER_Z_INDEX,
} from '~/constants/WorkspaceSpacing';
import { useSearchHeaderHeightContext } from '~/providers/SearchHeaderHeightProvider';

export interface SearchStickyHeaderContainerProps {
  children: ReactNode;
  showBottomBorder: boolean;
  tx?: BoxProps['tx'];
  headerRef?: MutableRefObject<HTMLDivElement | null>;
}

export const SearchStickyHeaderContainer = memo(
  ({ children, showBottomBorder, tx, headerRef }: SearchStickyHeaderContainerProps) => {
    const { isAboveMediumScreen } = useBreakpointsContext();
    const { setSearchHeaderHeight } = useSearchHeaderHeightContext();

    useEffect(() => {
      const headerHeight = headerRef?.current?.getBoundingClientRect();
      if (headerHeight) {
        setSearchHeaderHeight(headerHeight?.height);
      }
    }, [headerRef, isAboveMediumScreen, setSearchHeaderHeight]);

    return (
      <Box
        className="bg-grey-1"
        ref={headerRef}
        as="header"
        data-testid={SEARCH_STICKY_HEADER_CONTAINER}
        tx={{
          position: 'sticky',
          top: 0,
          /* Avatar for workspace needs to covered by expanding search bar */
          zIndex: SEARCH_STATIC_HEADER_Z_INDEX,
          boxSizing: 'border-box',
          height: 'auto',
          // these paddings are fallbacks, the responsive values should be passed in
          px: [HEADER_MOBILE_HORIZONTAL_PADDING, HEADER_MOBILE_HORIZONTAL_PADDING, HEADER_DESKTOP_HORIZONTAL_PADDING],
          borderBottom: showBottomBorder ? `1px solid` : '0px none',
          borderColor: showBottomBorder ? 'pigeon100' : 'none',
          ...iOSSafariFixedElementHackTx,
          ...tx,
        }}
      >
        {children}
      </Box>
    );
  },
);

SearchStickyHeaderContainer.displayName = 'SearchStickyHeaderContainer';
