import { Box } from '@air/zephyr';
import Link from 'next/link';

import Air from '~/assets/AirLogo';
import { Routes } from '~/constants/routes';

export const PasswordPageHeader = () => {
  return (
    <Box
      as="header"
      tx={{
        gridArea: 'header-bar',
        zIndex: 3,
        borderTop: '2px solid',
        borderTopColor: 'peacock400',
        borderBottomColor: 'transparent',
        position: 'absolute',
        backgroundColor: 'transparent',
        width: '100%',
      }}
    >
      <Box tx={{ display: 'flex', alignItems: 'center', mx: 'auto', height: 60 }}>
        <Box
          tx={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: ['1.5rem', '2rem'],
          }}
        >
          <Link href={Routes.root} passHref={true} legacyBehavior>
            <Box
              as="a"
              tx={{
                boxSizing: 'border-box',
                display: 'flex',
              }}
            >
              <Air fill="jay600" />
            </Box>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

PasswordPageHeader.displayName = 'PasswordPageHeader';
