import { DropdownMenu, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { Button } from '@air/zephyr';

import { useCopyToWorkspace } from '~/components/CopyToWorkspace/hooks/useCopyToWorkspace';
import { PUBLIC_BOARD_DOWNLOAD_MENU_TRIGGER } from '~/constants/testIDs';
import { useDownloadPublicBoard } from '~/hooks/useDownloadPublicBoard';
import { BoardPageBoard } from '~/store/centralizedBoard/types';
import { getDownloadMenuOptions } from '~/utils/menuOptions/getDownloadMenuOptions';

interface PublicDownloadButtonProps {
  board: BoardPageBoard;
}

export const PublicBoardDownloadMenu = ({ board }: PublicDownloadButtonProps) => {
  const { downloadPublicBoard } = useDownloadPublicBoard();
  const { copyToAir } = useCopyToWorkspace();

  const menuOptions = getDownloadMenuOptions({
    onDownload: () =>
      downloadPublicBoard({
        boardId: board.id,
        trackLocation: 'header',
      }),
    onSaveToAir: () =>
      copyToAir({
        boardId: board.id,
        trackLocation: 'header',
      }),
  });

  return (
    <DropdownMenu
      trigger={
        <Button tx={{ ml: 12 }} variant="button-filled-blue" data-testid={PUBLIC_BOARD_DOWNLOAD_MENU_TRIGGER}>
          Save to...
        </Button>
      }
    >
      {renderDropdownItems({ options: menuOptions })}
    </DropdownMenu>
  );
};
