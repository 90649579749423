import { useTrackViewedAsset } from '@air/analytics';
import { useShortIdContext } from '@air/provider-short-id';
import { Portal } from '@air/zephyr';
import { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AssetModal } from '~/components/AssetModal/AssetModal';
import { usePublicSSOAssetCallback } from '~/components/PublicSSOCallback/usePublicSSOAssetCallback';
import { usePublicWorkspace } from '~/components/PublicWorkspaceProvider';
import { useURLClipIdSelector } from '~/hooks/useURLClipIdSelector';
import { clearCentralizedClipAction, setCentralizedClipAction } from '~/store/centralizedClip/actions';
import { centralizedClipAssetIdSelector, centralizedClipIsDemoSelector } from '~/store/centralizedClip/selectors';
import { useGetPublicBoardClip } from '~/swr-hooks/shortUrl/useGetPublicBoardClip';
import { useAirStore } from '~/utils/ReduxUtils';

import { PublicBoardAssetModalDesktopLayout } from './components/PublicBoardAssetModalDesktopLayout';
import { PublicBoardAssetModalMobileLayout } from './components/PublicBoardAssetModalMobileLayout';

export const PublicBoardAssetModal = memo(() => {
  const store = useAirStore();
  const assetId = useSelector(centralizedClipAssetIdSelector);
  const { workspaceName } = usePublicWorkspace();
  const dispatch = useDispatch();
  const { shortId } = useShortIdContext();
  const clipId = useURLClipIdSelector();
  const { data: sharedClip } = useGetPublicBoardClip({ clipId, shortId });
  const { trackViewedAsset } = useTrackViewedAsset();

  usePublicSSOAssetCallback();

  useEffect(() => {
    if (sharedClip) {
      dispatch(setCentralizedClipAction({ clip: { ...sharedClip, boards: [] } }));
    }
  }, [sharedClip, workspaceName, assetId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearCentralizedClipAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (clipId) {
      const assetId = centralizedClipAssetIdSelector(store.getState());
      const isDemo = centralizedClipIsDemoSelector(store.getState());

      trackViewedAsset({ assetId, isDemo, clip_id: clipId });
    }
  }, [clipId, store, trackViewedAsset]);

  const desktop = useMemo(() => <PublicBoardAssetModalDesktopLayout />, []);
  const mobile = useMemo(() => <PublicBoardAssetModalMobileLayout />, []);

  return (
    <Portal>
      <AssetModal desktop={desktop} mobile={mobile} />
    </Portal>
  );
});

PublicBoardAssetModal.displayName = 'PublicBoardAssetModal';
