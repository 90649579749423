import { ViewTypeName } from '@air/api/types';
import { useBreakpointsContext } from '@air/provider-media-query';
import { memo, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { MobileToggleNestedItemsButton } from '~/_components/MobileToggleNestedItemsButton';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { PUBLIC_TOP_BAR_HEIGHT } from '~/components/PublicTopBar';
import { PublicSearchResultsNumber } from '~/components/Search/PublicSearchResultsNumber';
import { ViewControls, ViewControlsProps } from '~/components/ViewControls/ViewControls';
import { ViewKanbanGroupByMenu } from '~/components/ViewControls/ViewKanbanGroupByMenu/ViewKanbanGroupByMenu';
import { ViewSortingMenu } from '~/components/ViewControls/ViewSortingMenu/ViewSortingMenu';
import { ViewTypeToggleMenu } from '~/components/ViewControls/ViewTypeToggleMenu/ViewTypeToggleMenu';
import { PublicViewVisibilityToggleMenu } from '~/components/ViewControls/ViewVisiblityToggleMenu/PublicViewVisibilityToggleMenu';
import { ViewControlsBoardHeader } from '~/components/ViewControlsBoardHeader';
import { ONE_HOUR } from '~/constants/unitsOfTime';
import { PUBLIC_TABLE_HEADER_PADDING } from '~/constants/WorkspaceSpacing';
import { useIsSearchActive } from '~/hooks/search/useIsSearchActive';
import { useRerenderOnMount } from '~/hooks/useRerenderOnMount';
import { centralizedBoardAncestorsSelector, centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';
import { currentKanbanGroupByCustomFieldIdSelector, currentViewTypeNameSelector } from '~/store/configViews/selectors';
import { LocationBoard } from '~/store/router/types';
import { useGoToPublicBoard } from '~/swr-hooks/boards/useGoToPublicBoard';
import { useGetPublicCustomFieldOptions } from '~/swr-hooks/customFields/useGetPublicCustomFieldOptions';
import { canSeeBoardCustomFields } from '~/utils/permissions/boardPermissions';

export type PublicBoardViewControlsProps = Pick<ViewControlsProps, 'scrollRef' | 'headerRef'>;

export const PublicBoardViewControls = memo(({ scrollRef, headerRef }: PublicBoardViewControlsProps) => {
  const [isScrolledToTop, setIsScrolledToTop] = useState(false);
  const boardTitle = useSelector(centralizedBoardTitleSelector);
  const ancestors = useSelector(centralizedBoardAncestorsSelector);
  const { isSearchActive } = useIsSearchActive();
  const { goToPublicBoard } = useGoToPublicBoard();
  const onBoardClick = useCallback((board: LocationBoard) => goToPublicBoard(board, 'breadcrumb'), [goToPublicBoard]);
  const currentViewTypeName = useSelector(currentViewTypeNameSelector);
  const inGalleryView = currentViewTypeName === ViewTypeName.gallery;
  const inKanbanView = currentViewTypeName === ViewTypeName.kanban;
  const { isAboveMediumScreen } = useBreakpointsContext();
  const { isMounted } = useRerenderOnMount();
  const { permissions } = usePublicPermissions();
  const canViewCustomFields = canSeeBoardCustomFields(permissions);

  const customFieldId = useSelector(currentKanbanGroupByCustomFieldIdSelector);
  const { data: options } = useGetPublicCustomFieldOptions({
    customFieldId,
    staleTime: ONE_HOUR,
  });

  const header = useMemo(() => {
    /**
     * We need to check if mounted here because on the server side, isActive is false but on first render,
     * if the user has a search query param in the url, isActive will be true so this check prevents
     * a rehydration error
     *  */
    if (isSearchActive && isMounted) {
      return <PublicSearchResultsNumber />;
    } else if (isScrolledToTop && boardTitle) {
      return <ViewControlsBoardHeader title={boardTitle} ancestors={ancestors} onBoardClick={onBoardClick} />;
    } else if (!isAboveMediumScreen && (inGalleryView || inKanbanView)) {
      return <ViewSortingMenu />;
    }

    return null;
  }, [
    ancestors,
    boardTitle,
    inGalleryView,
    inKanbanView,
    isAboveMediumScreen,
    isMounted,
    isScrolledToTop,
    isSearchActive,
    onBoardClick,
  ]);

  const buttons = useMemo(
    () => (
      <>
        {inKanbanView && (
          <div className="mx-1">
            {canViewCustomFields && <ViewKanbanGroupByMenu customFieldValues={options?.data} />}
          </div>
        )}

        {isAboveMediumScreen && (inGalleryView || inKanbanView) && (
          <div className="mx-1">
            <ViewSortingMenu />
          </div>
        )}

        {!isAboveMediumScreen && <MobileToggleNestedItemsButton />}

        {isAboveMediumScreen && (
          <div className="mx-1">
            <PublicViewVisibilityToggleMenu />
          </div>
        )}
        <div className="ml-1">
          <ViewTypeToggleMenu canViewCustomFields={canViewCustomFields} />
        </div>
      </>
    ),
    [canViewCustomFields, inGalleryView, inKanbanView, isAboveMediumScreen, options?.data],
  );
  const tx = useMemo(() => ({ px: PUBLIC_TABLE_HEADER_PADDING }), []);

  return (
    <ViewControls
      headerRef={headerRef}
      scrollRef={scrollRef}
      buttons={buttons}
      header={header}
      onScrolled={setIsScrolledToTop}
      tx={tx}
      topPosition={PUBLIC_TOP_BAR_HEIGHT}
    />
  );
});

PublicBoardViewControls.displayName = 'PublicBoardViewControls';
