import { usePublicWorkspace } from '~/components/PublicWorkspaceProvider';
import { PUBLIC_WORKSPACE_INFO, PUBLIC_WORKSPACE_INFO_IMAGE, PUBLIC_WORKSPACE_INFO_NAME } from '~/constants/testIDs';

export const PublicWorkspaceInfo = () => {
  const { workspaceName, workspaceImage } = usePublicWorkspace();

  return (
    <div className="flex items-center" data-testid={PUBLIC_WORKSPACE_INFO}>
      <div className="relative mr-3 h-8 min-w-[32px] overflow-hidden rounded" data-testid={PUBLIC_WORKSPACE_INFO_IMAGE}>
        <img alt={`Logo for ${workspaceName}`} className="h-full w-8 object-cover" src={workspaceImage} />
      </div>
      <div className="mr-2 truncate text-16 font-medium text-grey-10" data-testid={PUBLIC_WORKSPACE_INFO_NAME}>
        {workspaceName}
      </div>
    </div>
  );
};
