import { Portal } from '@air/zephyr';
import { memo } from 'react';

import { CopyToWorkspacePanel } from '~/components/CopyToWorkspace/CopyToWorkspacePanel';

import { PublicZippingTasksPanel } from './ZippingTasks/PublicZippingTasksPanel';

export const PublicBoardActivityContainer = memo(() => {
  return (
    <Portal>
      <div className="fixed bottom-6 right-4 z-1">
        <CopyToWorkspacePanel />
        <PublicZippingTasksPanel />
      </div>
    </Portal>
  );
});

PublicBoardActivityContainer.displayName = 'PublicBoardActivityContainer';
