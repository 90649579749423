import { useTrackClickedEllipsisMenuButton } from '@air/analytics';
import { Board, PublicBoard } from '@air/api/types';
import { Ellipsis as EllipsisHorizontalIcon } from '@air/next-icons';
import { type DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { itemIsSelectedSelector, selectedItemsCountSelector } from '@air/redux-selected-items';
import pluralize from 'pluralize';
import { memo, useCallback, useMemo } from 'react';
import { flushSync } from 'react-dom';

import { CardAction } from '~/components/CardAction';
import { CardEllipsisMenu } from '~/components/Gallery/CardEllipsisMenu';
import { GetSelectionMenuOptionsType } from '~/components/Gallery/types';
import { GetItemMenuOptions } from '~/constants/MenuOptions';
import { BOARD_CARD_MENU, BOARD_CARD_MENU_BUTTON } from '~/constants/testIDs';
import { useAirSelector, useAirStore } from '~/utils/ReduxUtils';

export interface BoardGalleryCardProps<C> {
  board: C;
  getItemMenuOptions: GetItemMenuOptions;
  getSelectionMenuOptions: GetSelectionMenuOptionsType;
  setIsMenuOpen: (value: boolean) => void;
}

const _BoardGalleryCardMenu = <C extends Board | PublicBoard>({
  board,
  getItemMenuOptions,
  getSelectionMenuOptions,
  setIsMenuOpen,
}: BoardGalleryCardProps<C>) => {
  const { trackClickedEllipsisMenuButton } = useTrackClickedEllipsisMenuButton();
  const store = useAirStore();
  const selectedCount = selectedItemsCountSelector(store.getState());
  const isSelected = useAirSelector((st) => itemIsSelectedSelector(st, board.id));

  const selectionMenuOptions = getSelectionMenuOptions('gallery-multi-select-ellipsis-menu');
  const ellipsisMenuOptions = getItemMenuOptions({ trackLocation: 'gallery-item-ellipsis-menu' });

  const onMenuChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        trackClickedEllipsisMenuButton({ location: 'asset-item' });
      }

      flushSync(() => {
        setIsMenuOpen(isOpen);
      });
    },
    [setIsMenuOpen, trackClickedEllipsisMenuButton],
  );

  const menuOptions = useMemo(() => {
    const menuOptionsArray: DropdownMenuOption[] = [];

    if (isSelected && selectionMenuOptions.length) {
      menuOptionsArray.push({
        id: 'selected-header',
        type: 'title',
        label: `${pluralize('item', selectedCount, true)} selected`,
      });

      menuOptionsArray.push(...selectionMenuOptions);
    } else {
      menuOptionsArray.push(...ellipsisMenuOptions);
    }

    return menuOptionsArray;
  }, [ellipsisMenuOptions, isSelected, selectedCount, selectionMenuOptions]);

  if (!menuOptions?.length) {
    return null;
  }

  return (
    <CardEllipsisMenu
      title="More board options"
      menuProps={{ options: menuOptions }}
      onMenuChange={onMenuChange}
      actionSheetTestId={BOARD_CARD_MENU}
      trigger={
        <CardAction data-testid={BOARD_CARD_MENU_BUTTON} icon={<EllipsisHorizontalIcon className="h-4 w-4" />} />
      }
    />
  );
};

export const BoardGalleryCardMenu = memo(_BoardGalleryCardMenu) as typeof _BoardGalleryCardMenu;
