import { ColorName } from '@air/zephyr';
import { useTheme } from 'styled-components';

export interface AirProps {
  fill?: ColorName;
}

function Air({ fill = 'jay600' }: AirProps) {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="32" viewBox="0 0 350 144">
      <g fill={theme.colors[fill]} fillRule="evenodd" stroke="none" strokeWidth="1">
        <path d="M147.617 58.488c2.45-12.67 3.492-23.619 3.492-29.919C151.109 4.956 140.365 0 131.353 0c-19.144 0-46.195 22.141-82.697 67.69-26.076 32.537-47.432 65.52-47.644 65.848a6.312 6.312 0 001.878 8.73 6.313 6.313 0 008.729-1.88c31.903-49.395 92.62-127.76 119.734-127.76 2.133 0 7.127 0 7.127 15.941 0 6.227-1.14 17.313-3.735 29.957 0 0 7.343-.247 12.872-.038M222.43 46.512c0 5.486-4.448 9.934-9.935 9.934-5.486 0-9.933-4.448-9.933-9.934 0-5.486 4.447-9.934 9.933-9.934 5.487 0 9.934 4.448 9.934 9.934" />
        <path d="M251.376 92.738A114.675 114.675 0 01240 106.315c-15.33 15.69-33.125 25.056-47.6 25.056-7.453 0-11.232-3.186-11.232-9.47 0-6.645 3.645-19.298 21.007-41.839a6.317 6.317 0 00.874-6.162 6.318 6.318 0 00-4.835-3.918l-.005.005.005-.032C155.506 62.806 98.435 59.5 69.084 84.343v.028c-14.632 12.386-15.769 26.242-15.769 30.193 0 16.771 12.775 29.416 29.715 29.416 23.02 0 41.912-17.346 54.635-50.16a178.444 178.444 0 003.562-10.148c-4.67.008-9.163.167-13.443.471-8.538 24.562-22.77 47.208-44.754 47.208-11.217 0-17.086-8.444-17.086-16.787 0-5.312 2.968-13.502 11.3-20.555v-.027C90.721 82.574 120.5 71.41 185.925 80.832c-11.674 16.66-17.385 30.171-17.385 41.07 0 16.311 12.854 22.098 23.86 22.098 18.07 0 38.711-10.519 56.633-28.859 5.863-6 10.964-12.365 15.165-18.795-8.24-.465-12.822-3.608-12.822-3.608" />
        <path d="M348.148 112.738a6.313 6.313 0 00-8.911-.57c-13.861 12.191-28.13 19.183-39.145 19.183-6.54 0-9.855-3.423-9.855-10.175 0-7.297 3.914-21.077 22.555-45.278a6.316 6.316 0 00-8.602-9.04l-.001.001v-.002c-9.404 6.526-23.156 10.91-34.221 10.91-14.007 0-20.388-8.516-20.388-16.43 0-9.635 4.978-12.18 7.61-12.18 6.767 0 7.467 7.511 7.52 9.813.079 3.452-.551 7.291-1.782 11.353 0 0 6.119 2.153 12.876.698 1.083-4.318 1.62-8.47 1.53-12.343-.301-13.042-8.584-22.15-20.143-22.15-10.058 0-20.24 8.522-20.24 24.81 0 8.815 3.835 16.61 10.796 21.945 5.984 4.587 13.876 7.112 22.22 7.112 6.21 0 12.962-1.07 19.564-2.993l-.001.001c-7.923 13.21-11.921 24.52-11.921 33.773 0 15.753 11.292 22.804 22.483 22.804 19.226 0 38.003-13.99 47.486-22.33a6.312 6.312 0 00.57-8.912" />
      </g>
    </svg>
  );
}

export default Air;
