import { DropdownMenu, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { Button } from '@air/zephyr';
import { memo } from 'react';

import { ASSET_DOWNLOAD_DROPDOWN_MENU_TRIGGER } from '~/constants/testIDs';

import { usePublicDownloadOptions } from '../shared/hooks/usePublicDownloadOptions';

export const PublicDownloadButton = memo(() => {
  const options = usePublicDownloadOptions();

  return (
    <DropdownMenu
      trigger={
        <Button
          size="medium"
          data-testid={ASSET_DOWNLOAD_DROPDOWN_MENU_TRIGGER}
          tx={{ mr: 8, bg: 'jay350', ['&:hover']: { bg: 'jay400' } }}
        >
          Download
        </Button>
      }
    >
      {renderDropdownItems({ options })}
    </DropdownMenu>
  );
});

PublicDownloadButton.displayName = 'PublicDownloadButton';
