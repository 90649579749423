import { Link } from '@air/next-icons';
import { useBreakpointsContext } from '@air/provider-media-query';
import { useAirModal } from '@air/provider-modal';
import { useShortIdContext } from '@air/provider-short-id';
import { Button } from '@air/zephyr';

import { SharePublicBoardModal } from '~/components/Modals/SharePublicBoardModal';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { PublicBoardDownloadMenu } from '~/components/Shared/HeaderBar/PublicBoardDownloadMenu';
import { SHARE_BUTTON } from '~/constants/testIDs';
import { BoardPageBoard } from '~/store/centralizedBoard/types';
import { getPublicSubboardShareUrl } from '~/swr-hooks/shortUrl/utils';
import { canGenerateZip } from '~/utils/permissions/taskPermissions';

export interface PublicBoardHeaderActionsProps {
  board: BoardPageBoard;
}

export const PublicBoardHeaderActions = ({ board }: PublicBoardHeaderActionsProps) => {
  const { isAboveMediumScreen } = useBreakpointsContext();
  const [showSharePublicBoardModal] = useAirModal(SharePublicBoardModal);
  const { permissions } = usePublicPermissions();
  const { shortId } = useShortIdContext();

  const canDownload = canGenerateZip(permissions);

  if (!isAboveMediumScreen) {
    return null;
  }

  const onShareClick = () =>
    showSharePublicBoardModal({
      id: board.id,
      url: getPublicSubboardShareUrl(shortId, board.id),
      trackLocation: 'header',
    });

  return (
    <div className="flex shrink-0">
      <Button
        variant="button-filled-grey"
        data-testid={SHARE_BUTTON}
        onClick={onShareClick}
        tx={{
          width: canDownload ? 40 : 'unset',
          px: canDownload ? 0 : 16,
          color: canDownload ? 'pigeon500' : 'pigeon600',
        }}
      >
        {canDownload ? <Link className="h-5 w-5" /> : 'Share'}
      </Button>
      {canDownload && <PublicBoardDownloadMenu board={board} />}
    </div>
  );
};
