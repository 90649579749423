import styled, { css } from 'styled-components';

import { PUBLIC_TOP_BAR_HEIGHT } from '~/components/PublicTopBar';
import { Content } from '~/components/Shared/ClipGallery';
import MQ from '~/utils/MediaQueries';

const headerHeight = '2.5rem';

export const PublicMain = styled(Content)`
  display: flex;
  flex-direction: column;
  height: ${`calc(100% - ${PUBLIC_TOP_BAR_HEIGHT}px)`};
  /* height: fit-content; */
  /* max-height: 100%; */
`;

const alignMiddleOfPage = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 100vh;
  left: 0;
  padding: 2rem;
  position: absolute;
  top: calc(-${headerHeight} * 1.6);
  width: 100vw;
`;

export const ActiveInner = styled.div`
  ${alignMiddleOfPage}

  > button {
    margin-top: 2rem;
  }
  ${MQ.min.small(css`
    > button {
      display: none;
    }
  `)}
`;

export const CompletedInner = styled.div`
  ${alignMiddleOfPage}

  > h1 {
    margin-top: 1rem;
  }
  > h2 {
    margin-top: 0.5rem;
  }
  > button {
    margin-top: 1.25rem;
    font-size: 14px;
  }
  > p {
    margin-top: 2rem;
  }
`;
