import { Search as SearchIcon } from '@air/next-icons';
import { useBreakpointsContext } from '@air/provider-media-query';
import { IconButton } from '@air/zephyr';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { PublicBoardFiltersButton } from '~/components/Filters/FiltersButton/PublicBoardFiltersButton';
import { IOSSafariFixedElementHackBuffer } from '~/components/iOSFixedElementHack';
import { PublicSearch } from '~/components/Search/PublicSearch';
import {
  SearchStickyHeaderContainer,
  SearchStickyHeaderContainerProps,
} from '~/components/SearchStickyHeaderContainer';
import { SearchRow } from '~/components/Shared/HeaderBar/SearchRow';
import { PUBLIC_TABLE_HEADER_PADDING } from '~/constants/WorkspaceSpacing';
import { useMobileSearchBar } from '~/hooks/search/useMobileSearchBar';
import { useSearchHeaderHeightContext } from '~/providers/SearchHeaderHeightProvider';
import { centralizedBoardSelector } from '~/store/centralizedBoard/selectors';

import { PublicBoardHeaderActions } from './PublicBoardHeaderActions';

export type HeaderBarProps = Pick<SearchStickyHeaderContainerProps, 'headerRef'>;

export const PublicHeaderBar = memo(({ headerRef }: HeaderBarProps) => {
  const { isAboveMediumScreen } = useBreakpointsContext();
  const { openMobileSearch, isMobileSearchOpen, closeMobileSearch } = useMobileSearchBar();
  const { searchHeaderHeight } = useSearchHeaderHeightContext();

  const board = useSelector(centralizedBoardSelector);

  return (
    <>
      <SearchStickyHeaderContainer
        showBottomBorder={false}
        headerRef={headerRef}
        tx={{ px: PUBLIC_TABLE_HEADER_PADDING, zIndex: isMobileSearchOpen ? 6 : 5 }} // increase zIndex to show it above workspace
      >
        <div className="flex justify-between py-2 md:pb-3 md:pt-4">
          <SearchRow>
            <div className="flex grow justify-end md:justify-start">
              {isAboveMediumScreen || isMobileSearchOpen ? (
                <PublicSearch onCloseClick={closeMobileSearch} />
              ) : (
                <IconButton icon={SearchIcon} onClick={openMobileSearch} variant="button-ghost-grey">
                  Search
                </IconButton>
              )}
              <div className="ml-2">
                <PublicBoardFiltersButton boardId={board?.id ?? ''} />
              </div>
            </div>
          </SearchRow>
          {!!board && <PublicBoardHeaderActions board={board} />}
        </div>
      </SearchStickyHeaderContainer>
      <IOSSafariFixedElementHackBuffer height={searchHeaderHeight || 0} aria-hidden="true" />
    </>
  );
});

PublicHeaderBar.displayName = 'PublicHeaderBar';
