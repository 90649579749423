import { Comment } from '@air/next-icons';
import { Box } from '@air/zephyr';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { DarkIconButton } from '~/components/AssetModal/shared/components/DarkIconButton';
import { AssetModalPanel, useAssetModalPanelContext } from '~/providers/AssetModalPanelContextProvider';
import { centralizedClipOpenDiscussionCountSelector } from '~/store/centralizedClip/selectors';

export const MobileCommentsTab = memo(() => {
  const openDiscussionCount = useSelector(centralizedClipOpenDiscussionCountSelector);
  const { showPanel } = useAssetModalPanelContext();

  return (
    <Box tx={{ position: 'relative' }}>
      <DarkIconButton
        testId="COMMENTS_PANEL"
        icon={Comment}
        onClick={() => showPanel(AssetModalPanel.COMMENTS)}
        size="medium"
      >
        Comments panel
      </DarkIconButton>
      {!!openDiscussionCount && openDiscussionCount > 0 && (
        <Box
          tx={{
            position: 'absolute',
            right: 7,
            top: 7,
            width: 10,
            height: 10,
            borderRadius: '50%',
            bg: 'flamingo300',
          }}
        />
      )}
    </Box>
  );
});

MobileCommentsTab.displayName = 'PrivateMobileCommentsTab';
